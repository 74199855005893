@import "primeicons/primeicons.css";
@import "primeng/resources/themes/saga-green/theme.css";
@import "primeng/resources/primeng.css";

:root {
    --primary-color: #00A651;
    --secondary-color: #00305A;
    --hover-color: #008C44;
    --text-color: #333333;
    --navbar-border-color: #008C44;
    --dropdown-bg-color: #f9f9f9;
    --surface-ground: #E6F9F0;
    --surface-card: #FFFFFF;
    --text-on-primary: #FFFFFF;
    --font-family: 'Montserrat', sans-serif;
}

body {
    margin: 0;
    font-family: var(--font-family);
    padding-top: 60px;
    font-size: 18px;
}